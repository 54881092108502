import ConditionCard from './ConditionCard.vue';

import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
export default defineComponent({
    name: "ConditionList",
    props: {
        //以弹出框的形式包含该模块的时候会传入该参数(由jh.core.js的dialog传入)，在此模块中用该参数控制固定列的显示
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj=reactive({
            pageList: {
                pageListRef:null,
                queryParam: {
                    name:"",
                    templateId:props.engineParams?props.engineParams.templateId:''
                },
                modelComp: ConditionCard,
                modelMethod: utils.Api.buildUrl("/condition/pageData")
            }
        })
        return{
            ...toRefs(dataObj)
        }
    },
    components: {}
});